<template>
  <div justify="center">
    <v-icon
      v-if="showBtn"
      size="17"
      class="me-1"
      @click.stop="openForm"
    >
      {{ icon.mdiFormatListBulleted }}
    </v-icon>
    <v-btn
      v-else
      color="secondary"
      outlined
      elevation="0"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icon.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1100px"
    >
      <div id="customer-list">
        <v-card>
          <v-card-title> 契約科目 </v-card-title>
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field
              v-model="searchQuery"
              placeholder="Search"
              outlined
              hide-details
              dense
              class="user-search me-3 mb-4"
            >
            </v-text-field>
          </v-card-text>
          <v-data-table
            v-model="selectedList"
            item-key="ci_id"
            :headers="headers"
            show-select
            :items="tableList"
            :search="searchQuery"
            class="elevation-0"
            @item-selected="itemSelected($event)"
            @toggle-select-all="toggleSelectALl($event)"
          >
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-2"
              @click="goBack"
            >
              <span>OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="dialog = false"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapActions, mapState, mapMutations,
} from 'vuex'

import {
  mdiFormatListBulleted, mdiDotsHorizontal,
} from '@mdi/js'

export default {
  props: ['oldList', 'singleSelect', 'showBtn'],
  data: () => ({
    dialog: false,
    search: '',
    icon: {
      mdiFormatListBulleted,
      mdiDotsHorizontal,
    },
    searchQuery: '',
    selectedList: [],
    chipList: [],
    addList: [],
    tableList: [],
    headers: [
      {
        text: 'ID',
        align: 'left',
        sortable: true,
        value: 'ci_id',
        width: '10%',
        fixed: true,
      },
      {
        text: '契約科目名',
        value: 'ci_name',
        sortable: true,
        align: 'left',
        width: '18%',
        fixed: true,
      },
      {
        text: '契約科目内容(日本語)',
        value: 'ci_content_ja',
        sortable: true,
        align: 'left',
        width: '24%',
        fixed: true,
      },
      {
        text: '契約科目内容(中国語)',
        value: 'ci_content_cn',
        sortable: true,
        align: 'left',
        width: '24%',
        fixed: true,
      },
      {
        text: '契約科目内容(英語)',
        value: 'ci_content_en',
        sortable: true,
        align: 'left',
        width: '24%',
        fixed: true,
      },
    ],

  }),
  computed: {
    ...mapState('app', ['absoluteOverlayStatus', 'OverlayStatus']),
    ...mapState('contractStore', ['contractItemsList']),
  },
  watch: {
    // dialog() {
    //   if (this.id) {
    //     const item = this.tableList.find(tl => tl.id === this.id)
    //     this.selectedList.push(item)
    //   }
    // },
    tableList: {
      handler(newValue) {
        if (newValue.length !== 0 && this.oldList) {
          this.selectedList = this.oldList.map(ol => {
            const item = this.contractItemsList.find(tl => tl.ci_id === ol.ci_id)
            item.ci_input = ol.ci_input

            return item
          })
        }
        this.chipList = this.oldList.map(ol => {
          const item = this.contractItemsList.find(tl => tl.ci_id === ol.ci_id)
          item.ci_input = ol.ci_input
          item.cd_input = ol.cd_input

          return item
        })
      },
      deep: true,
    },
  },
  destroyed() {
    this.tableList = []
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapMutations('app', ['setAbsoluteOverlayStatus']),
    ...mapActions('contractStore', ['loadContractItems']),
    toggleSelectALl(event) {
      if (event.value) {
        this.chipList = event.items
      } else {
        this.chipList = []
      }
    },
    itemSelected(event) {
      if (event.value) {
        this.chipList.push(event.item)
      } else {
        this.remove(event.item)
      }
    },

    loadData() {
      this.setOverlayStatus(true)
      this.loadContractItems().then(() => {
        // this.tableList = this.$deepCopy(this.contractItemsList)
        this.tableList = [...this.contractItemsList]
        this.dialog = true
        this.setOverlayStatus(false)
      })
    },

    openForm() {
      this.selectedList = []
      this.loadData()
      console.log(this.oldList)
    },

    goBack() {
      this.dialog = false
      this.addList = this.chipList
      this.$emit('selectedList', this.addList)
    },
    remove(item) {
      const index = this.selectedList.findIndex(el => el.ci_id === item.ci_id)
      if (index >= 0) this.selectedList.splice(index, 1)
      const indexChip = this.chipList.findIndex(el => el.ci_id === item.ci_id)
      if (indexChip >= 0) this.chipList.splice(indexChip, 1)
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
