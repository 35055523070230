<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title class="py-9">
          <span> 契約書条目(編集) </span>
        </v-card-title>
        <v-spacer></v-spacer>
        <template v-if="apierror.status == 'error'">
          <div v-for="msg in apierror.messages" :key="msg">
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>

        <v-form class="pa-6 py-3">
          <v-row align="center">
            <v-col
              cols="12"
              md="2"
            >
              <label
                class="text-right"
              >取引先ID</label>
            </v-col>
            <v-col
              cols="12"
              md="10"
            >
              <span>{{ selectedDocument.cust_abbr }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="2"
              class="d-flex align-center flex-wrap"
            >
              <span>新規テンプレート名</span>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="Form.tmpl_name"
                :error-messages="tmplNameErrors"
                outlined
                dense
                placeholder="テンプレート名"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <ContractItems
                class="ml-auto"
                :old-list="Form.details"
                @selectedList="getSelectedList"
              ></ContractItems>
            </v-col>
          </v-row>
        </v-form>
        <v-data-table
          ref="Templates"
          :headers="Headers"
          :items="Form.details"
          item-key="ci_id"
          class="elevation-0 "
        >
          <template v-slot:[`item.cd_input`]="{ item }">
            <v-text-field
              v-model="item.cd_input"
              outlined
              dense
              hide-details="auto"
            >
            </v-text-field>
          </template>
        </v-data-table>
        <v-card-actions
          align="center"
          class="d-flex justify-center"
        >
          <v-btn
            color="primary"
            :loading="submitStatus"
            @click="submit(Form)"
          >
            Submit
          </v-btn>
          <v-btn
            outlined
            @click="cancelClick"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencil,
  mdiPageNextOutline,
  mdiMinusCircle,
} from '@mdi/js'

import {
  mapActions, mapMutations, mapState,
} from 'vuex'
import Sortable from 'sortablejs'
import {
  required,
} from 'vuelidate/lib/validators'
import ContractItems from '@/components/ContractItems.vue'

export default {
  components: {
    ContractItems,
  },
  data: () => ({
    id: '',
    icons: {
      mdiPlus,
      mdiPencil,
      mdiMinusCircle,
      mdiPageNextOutline,
    },
    Headers: [
      {
        text: 'No.',
        align: 'left',
        sortable: true,
        value: 'disp_order',
        width: '10%',
        fixed: true,
      },
      {
        text: '契約科目名',
        value: 'ci_name',
        sortable: true,
        align: 'left',
        width: '20%',
        fixed: true,
      },
      {
        text: '入力内容',
        value: 'cd_input',
        sortable: true,
        align: 'left',
        width: '70%',
        fixed: true,
      },
    ],
    Form: {
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    submitStatus: false,
  }),
  validations: {
    Form: {
      tmpl_name: {
        required,
      },
    },
  },

  computed: {
    ...mapState('contractStore', ['selectedContract']),
    ...mapState('documentStore', ['selectedDocument']),
    ...mapState('templateStore', ['templateList', 'selectedTemplate', 'templateNameId']),

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    tmplNameErrors() {
      const errors = []
      if (!this.$v.Form.tmpl_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.tmpl_name.required && errors.push('必須項目')

      return errors
    },
  },
  watch: {
    Form: {
      handler() {
        this.apierror.messages = []
      },
      deep: true,
    },
  },

  mounted() {
    this.loadData()
    this.sortDocuments()
  },
  methods: {
    ...mapActions('contractStore', ['loadContract', 'editContract']),
    ...mapActions('templateStore', ['loadTemplates', 'loadTemplate']),
    ...mapActions('documentStore', ['loadDocument', 'editDocument']),
    ...mapMutations('app', ['setOverlayStatus']),
    sortDocuments() {
      const el = this.$refs.Templates.$el.querySelectorAll('.v-data-table__wrapper > table> tbody')[0]
      Sortable.create(el, {
        ghostClass: 'sortable-ghost',
        sort: true,
        animation: 150,
        group: {
          name: 'Template',
          pull: false,
          put: false,
        },
        setData(dataTransfer, dragEl) {
          dataTransfer.setData('Text', dragEl.textContent)
        },
        onEnd: evt => {
          // console.log(evt.oldIndex, evt.newIndex)
          const item = this.Form.details[evt.oldIndex]

          // console.log(item, this.Form.details)
          this.Form.details.splice(evt.oldIndex, 1)
          this.Form.details.splice(evt.newIndex, 0, item)

          const detailsLidt = []
          this.Form.details.forEach((v, i) => {
            detailsLidt[i] = v
            detailsLidt[i].disp_order = i + 1

            // console.log(i, v.disp_order, v.detail_id)
          })
        },
      })
    },
    getSelectedList(selectedList) {
      this.Form.details = selectedList.map((mal, i) => {
        const item = {
        }
        item.ci_id = mal.ci_id
        item.ci_name = mal.ci_name
        item.cd_input = mal.cd_input
        item.disp_order = i + 1

        return item
      })
    },

    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadTemplates(),
        this.loadDocument(this.$route.params.id),
      ]).then(() => {
        this.Form = this.$deepCopy(this.selectedDocument)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    loadTemp(item) {
      this.setOverlayStatus(true)
      this.loadTemplate(item)
        .then(() => {
          this.Form.details = this.$deepCopy(this.selectedTemplate.details)
          const newArray = this.Form.details.map(obj => ({
            ...obj, cd_input: obj.ci_input,
          }))
          this.Form.details = this.$deepCopy(newArray)
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.setOverlayStatus(false)
        })
    },
    change(item) {
      if (item === null) {
        this.Form.details = this.$deepCopy(this.selectedDocument.details)
      } else {
        this.loadTemp(item)
      }
    },

    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },

    jumpToListPage() {
      this.$router.push({
        path: '/document-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    submit(Form) {
      console.log('submit:', Form)

      this.submitStatus = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.editDocument(Form)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          }).finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
        window.scrollTo(0, 0)
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.apierror.messages.push('赤い部分の情報を修正してください')
        this.submitStatus = false
      }
    },
  },

}
</script>

<style lang="scss">
.add-products-form {
  .single-product-form {
    &:not(:first-child) {
      margin-top: 2rem;
    }
  }
}
.custom-textarea {
  // max-height: 50px; /* 指定最大高度，根据需求进行调整 */
}
</style>
